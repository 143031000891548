<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="additionalInfoArticles"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getAdditionalInfoArticlesTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <router-link
              :to="{ name: 'InfoConstructorCategoriesList', params: { id: currentCompanyId } }"
              class="text-decoration-none v-toolbar__title"
            >
              Категорії
            </router-link>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title v-if="categoryId">Статті категорії "{{ categoryName }}"</v-toolbar-title>
            <v-toolbar-title v-else>Статті всіх категорій</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-btn :to="{ name: 'InfoConstructArticle', query: { categoryId } }" color="primary" link small>
              Додати статтю
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            v-if="categoryId"
            :to="{ name: 'InfoArticleCard', query: { articleId: item.id } }"
            class="text-decoration-none"
          >
            {{ item.id }}
          </router-link>
          <span v-else>{{ item.id }}</span>
        </template>
        <template v-slot:item.name.uk="{ item }">
          <router-link :to="{ name: 'InfoArticleCard', query: { articleId: item.id } }" class="text-decoration-none">
            {{ item.name[$currentLocale] }}
          </router-link>
        </template>
        <template v-slot:item.category.name="{ item }">
          <router-link
            :to="{
              name: 'InfoConstructorArticlesList',
              params: { id: currentCompanyId },
              query: { categoryId: (item.category && item.category.id) || 0 },
            }"
            class="text-decoration-none"
          >
            {{ (item.category && item.category.name[$currentLocale]) || '' }}
          </router-link>
        </template>
        <template v-slot:item.event_code.device_type="{ item }">
          <span v-if="item.event_code">
            {{ getDeviceTitle(item.event_code.device_type) }}
          </span>
        </template>
        <template v-slot:item.event_code="{ item }">
          <span v-if="item.event_code">
            {{ item.event_code.name[$currentLocale] }} ({{ item.event_code.event_code }}),
            {{ item.event_code.event_type.name[$currentLocale] }} ({{ item.event_code.event_type.code }})
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <router-link :to="{ name: 'InfoConstructArticle', query: { articleId: item.id }, hash: '#edit' }">
                <v-icon small v-bind="attrs" v-on="on" class="mr-2"> mdi-pencil-outline </v-icon>
              </router-link>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <router-link :to="{ name: 'InfoArticleCard', query: { articleId: item.id } }">
                <v-icon small v-bind="attrs" v-on="on" class="mr-2"> mdi-eye-outline </v-icon>
              </router-link>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Статтю буде видалено</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
          <v-btn text @click="closeDelete">Нi</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import resetTableData from '@/mixins/resetTableData'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import BackButton from '@/components/common/BackButton.vue'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'InfoConstructorArticlesList',
  components: { BackButton },

  mixins: [ConvertDevicesTypes, resetTableData, AwaitingSearch, sortUtils],

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    search: '',
    loading: false,
    clickedArticleId: null,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Стаття', align: 'start', sortable: true, value: 'name.uk' },
      { text: 'Категорія', align: 'start', sortable: true, value: 'category.name.uk' },
      { text: 'Тип автомата', align: 'start', sortable: true, value: 'event_code.device_type' },
      { text: 'Подія', align: 'start', sortable: true, value: 'event_code' },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    sortBy: 'id',
    sortDesc: false,
    partnersList: [],
    partner: {},
    options: {},
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters('infoConstructor', ['getAdditionalInfoArticlesTotalCount']),
    ...mapState('infoConstructor', ['additionalInfoArticles', 'additionalInfoCategory']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    categoryId() {
      return this.$route.query.categoryId || ''
    },
    categoryName() {
      return this.additionalInfoCategory?.name ? this.additionalInfoCategory.name[this.$currentLocale] : ''
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
    async categoryId(val) {
      await this.loadSelectedAdditionalInfoCategory(val)
      this.options.page = 1
      await this.paginateTo()
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    ...mapActions('infoConstructor', [
      'loadAdditionalInfoArticles',
      'deleteSelectedAdditionalInfoArticle',
      'loadSelectedAdditionalInfoCategory',
    ]),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      if (this.categoryId) {
        await this.loadSelectedAdditionalInfoCategory(this.categoryId)
      }
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          category: this.categoryId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          search: this.search,
        }
        await this.loadAdditionalInfoArticles(params)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    deleteItem(item) {
      this.clickedArticleId = item.id
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      this.loading = true
      await this.deleteSelectedAdditionalInfoArticle(this.clickedArticleId)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },
    closeDelete() {
      this.dialogDelete = false
    },
  },
}
</script>

<style scoped></style>
