export default {
  methods: {
    getDeviceTitle(val) {
      return this.machinesTypesDictionary.find((machine) => machine.value === val)?.name || val
    },

    getConnectedDeviceTitle(val) {
      return this.connectedDevicesTypesDictionary.find((device) => device.value === val)?.name || val
    },

    getMachineDesc(val) {
      return this.machinesTypesDictionary.find((machine) => machine.value === val)?.description || val
    },

    getMachineDescByTitle(val) {
      return this.machinesTypesDictionary.find((machine) => machine.name === val)?.description || val
    },
  },
}
